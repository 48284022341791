var data;

/**
 * Global function for registering Seznam's "naseptavac" to input element.
 * @param {Array.<string>} e - Main input element (with searching address)
 * @param {string} u - Address input element which should be updated by SMap suggest
 * @param {string} o - City input element ..
 * @param {string} p - Postal code input element ..
 * @param {string} a - Full address input ..
 * @param {string} map_el - Map parent element (optional)
 */
function registerNaseptavac(e, u, o, p, a, map_el) {
    e.forEach((inpt) => {
        let el = document.querySelector(inpt);
        let suggest = new SMap.Suggest(el, {
            provider: new MyProvider(),
        });

        // update dat pro našeptávač
        suggest.getProvider().updateParams((params) => {
            params.type = "municipality|street|address";
            //params.category = "address_cz,region_cz,district_cz,municipality_cz,area_cz";
            params.locality = "sk|cz";
            params.lang = "cs,sk,en";
        });

        suggest.addListener("suggest", (suggestData) => {
            let result = suggestData.data.origData;
            console.log(suggestData);

            if (map_el) {
                let first = $(map_el).css("height");

                $(map_el).css("height", "220px");

                if (first !== "220px") {
                    $(".modal-body").scrollTop($(".modal-body").scrollTop() + 220);
                }
                // vytvoreni mapy
                let center = SMap.Coords.fromWGS84(result.longitude, result.latitude);
                let m = new SMap(JAK.gel("seznam-map"), center, 17);
                m.addDefaultLayer(SMap.DEF_BASE).enable();
                m.addDefaultControls();

                // add znacka
                let layer = new SMap.Layer.Marker();
                m.addLayer(layer);
                layer.enable();

                let options = {};
                let marker = new SMap.Marker(center, "myMarker", options);
                layer.addMarker(marker);
            }

            if (result.source !== "osm") {
                let ulice = result.suggestFirstRow;
                let obec = result.municipality;
                let psc = result.zipCode;

                if (obec) {
                    document.querySelector(o).value = obec;
                    if (ulice) {
                        document.querySelector(u).value = ulice;
                    }
                }
                if (psc) {
                    document.querySelector(p).value = psc;
                }
                if (a) {
                    document.querySelector(a).value = `${ulice}, ${psc}, ${obec}`;
                }
            } else {
                //SK
                let ulice = result.suggestFirstRow;
                let obec = "";
                //Neni to adresa, takze pravdepodobne se naslo jen mesto, nebudeme vyplnovat adresu
                if (result.poiTypeId !== 220) {
                    ulice = "";
                    obec = result.suggestFirstRow;
                } else {
                    obec = result.suggestSecondRow.split(", ")[1];
                }

                let url = "?do=getSkPsc&obec=" + encodeURIComponent(obec);
                if (obec) {
                    $.nette.ajax({
                        url: url,
                        success: function (result) {
                            if (result.status == "ok") {
                                document.querySelector(o).value = obec;
                                document.querySelector(u).value = ulice;
                                document.querySelector(p).value = result.psc;

                                /*let form = $(u).closest("form");
                                Nette.validateForm(form);*/
                            }
                        },
                    });
                }
            }
        });
    });
}

class MyItem extends SMap.SuggestItem {
    /**Upravime vytvareni polozky naseptavace - pridame info o cvrti pokude existuje*/
    _create() {
        let node = document.createElement("li");
        node.classList.add("item");

        let titlePart = document.createElement("span");
        titlePart.classList.add("text");

        let title = document.createElement("strong");
        title.innerHTML = this._data.title;

        titlePart.appendChild(title);

        // do prvniho radku naseptavace dame informaci o ctvrti; pokud neni, tak standardni vypis
        let d = this._data.origData;
        data = this._data.origData;

        let popisek = d.quarter ? d.quarter + (d.ward && d.ward != d.municipality ? " - " + d.ward : "") : d.secondRow;

        this._addRow(titlePart, popisek);
        this._addRow(titlePart, this._data.thirdRow);

        this._dom.node = node;

        node.appendChild(this._createIcon());
        node.appendChild(titlePart);
    }
}

class MyProvider extends SMap.SuggestProvider {
    /**
     Upravime ziskavani dat pro naseptavac - preposleme do suggest item vsechny puvodni data,
    kde se prave muze vyskytovat ctvrt.
    */
    _processData() {
        if (!this._responseData) return;

        let rawData = JSON.parse(this._responseData.data);

        if (rawData.result && Array.isArray(rawData.result)) {
            let resultsItems = rawData.result.map((item) => {
                let poiTypeId = item.userData.poiTypeId || 0;
                let firstRow = (item.userData.suggestFirstRow || "").trim();
                let id = item.userData.id;

                if (item.userData.source == "coor" && typeof id === "number") {
                    id = item.userData.longitude + "," + item.userData.latitude;
                }

                return {
                    coords: SMap.Coords.fromWGS84(item.userData.longitude, item.userData.latitude),
                    longitude: parseFloat(item.userData.longitude),
                    latitude: parseFloat(item.userData.latitude),
                    source: item.userData.source,
                    id: id,
                    title: firstRow,
                    secondRow: (item.userData.suggestSecondRow || "").trim(),
                    thirdRow: (item.userData.suggestThirdRow || "").trim(),
                    phrase: firstRow || item.sentence,
                    iconType: item.userData.iconType || "",
                    iconUrl: this._getIconUrl(poiTypeId),
                    poiTypeId: poiTypeId,
                    origData: item.userData, // pridame si informaci o vsech datech
                };
            });

            this._promise.fulfill(resultsItems);
        }

        this._promise = null;
        this._request = null;
    }
}
