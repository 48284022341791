!(function (t, n) {
    "use strict";
    if (t && n) {
        if (!n.supported()) {
            t.fn.filepond = function () {};
            return;
        }
        (t.fn.filepond = function () {
            var t,
                e = ((t = arguments), Array.prototype.slice.call(t)),
                i = [],
                r = this.each(function () {
                    if (!(u = e).length || "object" == typeof u[0]) {
                        n.create(this, e[0]);
                        return;
                    }
                    var t,
                        r,
                        o,
                        f,
                        p,
                        s,
                        c = n.find(this);
                    if (c) {
                        var u,
                            l,
                            h,
                            a = e[0],
                            d = e.concat().slice(1);
                        if (((l = c), "function" == typeof l[(h = a)])) {
                            i.push(c[a].apply(c, d));
                            return;
                        }
                        if (
                            ((t = c), (o = Object.getOwnPropertyDescriptor(t, (r = a))) && void 0 !== o.set && d.length)
                        ) {
                            c[a] = d[0];
                            return;
                        }
                        if (((f = c), (s = Object.getOwnPropertyDescriptor(f, (p = a))) && void 0 !== s.get)) {
                            i.push(c[a]);
                            return;
                        }
                        console.warn('$().filepond("' + a + '") is an unknown property or method.');
                    }
                });
            return i.length ? (1 === this.length ? i[0] : i) : r;
        }),
            Object.keys(n).forEach(function (e) {
                t.fn.filepond[e] = n[e];
            }),
            (t.fn.filepond.setDefaults = n.setOptions);
    }
})(jQuery, FilePond);
